import { HttpService } from "@/core/services";
export interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface Location {
    lat: number;
    lng: number;
}

export interface Northeast {
    lat: number;
    lng: number;
}

export interface Southwest {
    lat: number;
    lng: number;
}

export interface Viewport {
    northeast: Northeast;
    southwest: Southwest;
}

export interface Geometry {
    location: Location;
    location_type: string;
    viewport: Viewport;
}

export interface PlusCode {
    compound_code: string;
    global_code: string;
}

export interface Result {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: Geometry;
    place_id: string;
    plus_code: PlusCode;
    types: string[];
}

export interface GeocodeResult {
    results: Result[];
    status: string;
}

export class MapConfigurationModel {
    geocodeMapKey: string;
}

export const GeocodingService = {

    key: "",
    setKey(key: string) {
        this.key = key;
    },
    url: "https://maps.googleapis.com/maps/api/geocode/json",
    addressSearch(address: string) {
        return `${this.url}?address=${address}`;
    },
    getKey() {
        return HttpService.getData<MapConfigurationModel>("/config/map");
    },
    addKey(url: string) {
        return `${url}&key=${this.key}`;
    },
    search(address: string): Promise<GeocodeResult> {
        const geocodeUrl = this.addKey(this.addressSearch(address));
        return HttpService.getDataNoPragma<GeocodeResult>(geocodeUrl);
    },
};
