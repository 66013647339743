import { Method, AxiosRequestConfig } from "axios";
import _ from "lodash";

import { Map, Validatable, PagedCollectionFilter, PagedCollection, DataFilter } from "@/core/models";
import { AxiosFactory } from "@/core/services";

/**
 * Used for POST / PUT requests with Validatable models. Will automatically resolve
 * model state so it could be used by our custom input components.
 */
function modelRequest(url: string, verb: Method, model: Validatable) {
  model.modelState = {} as Map<string[]>;

  const promise = AxiosFactory.create().request({ url, method: verb, data: model });
  promise.then(
    () => {
      return;
    },
    (error: any) => {
      if (error.response && error.response.data) {
        model.modelState = error.response.data.modelState;
      }
    },
  );

  return promise;
}

export const HttpService = {
  // Axios wrapper methods (mainly for auth purposes)
  getUri: (config?: AxiosRequestConfig) => AxiosFactory.create().getUri(config),
  request: (config: AxiosRequestConfig) => AxiosFactory.create().request(config),
  get: (url: string, config?: AxiosRequestConfig) => AxiosFactory.create().get(url, config),
  delete: (url: string, config?: AxiosRequestConfig) => AxiosFactory.create().delete(url, config),
  head: (url: string, config?: AxiosRequestConfig) => AxiosFactory.create().head(url, config),
  post: (url: string, data?: any, config?: AxiosRequestConfig) => AxiosFactory.create().post(url, data, config),
  put: (url: string, data?: any, config?: AxiosRequestConfig) => AxiosFactory.create().put(url, data, config),
  patch: (url: string, data?: any, config?: AxiosRequestConfig) => AxiosFactory.create().patch(url, data, config),

  // Custom methods for saving time
  postModel(url: string, model: Validatable) {
    return modelRequest(url, "POST", model);
  },
  putModel(url: string, model: Validatable) {
    return modelRequest(url, "PUT", model);
  },
  // put version of data returned call
  async putData<T>(url: string, data: any, config?: AxiosRequestConfig) {
    const response = await AxiosFactory.create().put(url, data, config);
    return response.data as T;
  },
  async deleteData<T>(url: string, config?: AxiosRequestConfig) {
    const response = await AxiosFactory.create().delete(url, config);
    return response.data as T;
  },
  /**
   * Fetches data from the server and extracts the data from
   * the response as a strongly typed object.
   */
  async getData<T>(url: string, config?: AxiosRequestConfig) {
    const response = await AxiosFactory.create().get(url, config);
    return response.data as T;
  },

  /**
   * Fetches data from the server and extracts the data from
   * the response as a strongly typed object.
   */
  async getDataNoPragma<T>(url: string, config?: AxiosRequestConfig) {
    const response = await AxiosFactory.createNoPragma().get(url, config);
    return response.data as T;
  },
  /**
   * Fetches data with a post from the server and extracts the data from
   * the response as a strongly typed object.
   */
  async postData<T>(url: string, data: any, config?: AxiosRequestConfig) {
    const response = await AxiosFactory.create().post(url, data, config);
    return response.data as T;
  },
  /**
   * Wrapper around getData method, specifically designed for paged filtering.
   * Returns a strongly typed PagedCollection of data.
   */
  filterData<T, TDataFilter extends DataFilter>(url: string, filter: PagedCollectionFilter & TDataFilter) {
    return this.getData<PagedCollection<T>>(url, { params: filter });
  },
  /**
   * Wrapper around filterData method, specifically designed find input boxes
   * such as finding a user. Ensures search string exists, defaults certain
   * filter properties to get all available results. Allows filter overriding.
   * Returns a strongly typed array of result data.
   */
  async find<T, TDataFilter extends DataFilter>(url: string, filter: TDataFilter, extraParams: object = {}) {
    if (!filter || filter.search.length < 4) {
      return [];
    }

    const params = _.extend(
      {
        page: 1,
        pageSize: 0,
        search: filter.search,
      } as PagedCollectionFilter & TDataFilter,
      extraParams,
    );

    const result = await HttpService.filterData<T, TDataFilter>(url, params);

    return result.items;
  },
};
