import { HttpService } from "@/core/services";
import moment from "moment";
const rootUrl = "tag";
export class CreateTagModel {
    name: string;
    constructor() { this.name = ""; }
}
export class TagModel extends CreateTagModel {
    userTagId: number;
    accountCode: string;
    dateCreated: moment.Moment;
    dateUpdated: moment.Moment;
    dateDeleted?: moment.Moment;
    selected: boolean = false;
}
export class ProjectTagFilterResult {
    ProjectId: number;
    Tags: TagModel[];
}

export class ProjectTagFilterRequest {
    TagList: number[];
}

export const TagService = {
    async getAllTags() {
        return await HttpService.getData<TagModel[]>(`${rootUrl}/all`);
    },
    async getAllVisibleTags() {
        return await HttpService.getData<TagModel[]>(`${rootUrl}`);
    },
    async createTag(model: CreateTagModel) {
        return await HttpService.putData<TagModel>(`${rootUrl}`, model);
    },
    async deleteTag(tagId: number) {
        return await HttpService.deleteData<TagModel>(`${rootUrl}/${tagId}`);
    },
    async renameTag(model: TagModel) {
        return await HttpService.postData<TagModel>(`${rootUrl}`, model);
    },
    async getTagsForProject(projectId: number) {
        return await HttpService.getData<TagModel[]>(`${rootUrl}/project/${projectId}`);
    },
    async addTagToProject(projectId: number, userTagId: number) {
        return await HttpService.putData<TagModel[]>(`${rootUrl}/project/${projectId}/tag/${userTagId}`, {});
    },
    async addNewTagToProject(projectId: number, model: CreateTagModel) {
        return await HttpService.postData<TagModel[]>(`${rootUrl}/project/${projectId}`, model);
    },
    async removeTagFromProject(projectId: number, userTagId: number) {
        return await HttpService.deleteData<TagModel[]>(`${rootUrl}/project/${projectId}/tag/${userTagId}`, {});
    },

    async getTagsForCompany(companyId: number) {
        return await HttpService.getData<TagModel[]>(`${rootUrl}/company/${companyId}`);
    },
    async addTagToCompany(companyId: number, userTagId: number) {
        return await HttpService.putData<TagModel[]>(`${rootUrl}/company/${companyId}/tag/${userTagId}`, {});
    },
    async addNewTagToCompany(companyId: number, model: CreateTagModel) {
        return await HttpService.postData<TagModel[]>(`${rootUrl}/company/${companyId}`, model);
    },
    async removeTagFromCompany(companyId: number, userTagId: number) {
        return await HttpService.deleteData<TagModel[]>(`${rootUrl}/company/${companyId}/tag/${userTagId}`, {});
    },

    async searchProjectsByTag(model: ProjectTagFilterRequest) {
        return await HttpService.postData<ProjectTagFilterResult[]>(`${rootUrl}/filter`, model);
    },
};