import { CompanyDetailModel, SearchResults, SearchModel, ProjectSearchResultModel } from "@/core/models";
import { HttpService } from "@/core/services";

const routeRoot: string = "company";

export const CompanyService = {
  getCompanyConnections(companyId: number) {
    return HttpService.getData<any>(`${routeRoot}/${companyId}/connections`);
  },
  updateCompanyLocation(companyId: number, locationResult: any) {
    return HttpService.postData<CompanyDetailModel>(`${routeRoot}/${companyId}/location`, locationResult);
  },
  getCompany(companyId: number) {
    return HttpService.getData<CompanyDetailModel>(`${routeRoot}/${companyId}`);
  },
  getCompanyProjects(companyId: number, skip: number = 0, filter?: SearchModel) {
    let url = `${routeRoot}/${companyId}/projects`;
    if (skip > 0) {
      url += `?skip = ${skip}`;
    }
    if (!!filter) {
      return HttpService.postData<SearchResults<ProjectSearchResultModel>>(url, filter);
    }
    return HttpService.getData<SearchResults<ProjectSearchResultModel>>(url);
  },
  peek(companyId: number | string) {
    return HttpService.get(`${routeRoot}/${companyId}/peek`);
  },
};
