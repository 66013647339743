export const numberFormatterFilter = (value: number) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-IE");
  return formatter.format(value);
};

export const m2FormatterFilter = (value: number) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-IE");
  return `${formatter.format(value)} m2`;
};

export const areaFormatterFilter = (value: number) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-IE");
  return `${formatter.format(value)} ha`;
};
export const yesNoFormatter = (value: number) => {
  if (typeof value !== "boolean") {
    return value;
  }
  return value ? "Y" : "N";
};
