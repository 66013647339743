import { Moment } from "moment";
import { SearchModel } from "./search.models";

export class SavedSearchModel {
  isLoading: boolean;
  updates: number;
  userAzureSearchID: number;
  name: string;
  newName: string;
  searchModel: SearchModel;
  userID: number;
  upgradedUserSearchID: number;
  dateUpdated: Moment;
  dateCreated: Moment;
  isAlertEnabled: boolean;
  searchType: number;
}

export class CISOnlineSavedSearchModel {
  userSearchID: number;
  name: string;
  usesOspreySchema: boolean;
  usesPublicFacingSectorCategories: boolean;
  includeTenders: boolean;
  isUserProfileSearch: boolean;
  scaleTypeID: number;
  // criteriaXML:string;
  userID: number;
  dateCreated: Moment;
  entityTypeId: number;
}
