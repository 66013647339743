import { Vue } from "vue-property-decorator";
import * as moment from "moment";
import _ from "lodash";

// If token more than 180 minutes old, attempt token refresh
const tokenValidFor = 3 * 60;
const refreshThreshold = tokenValidFor - 60;
const REFRESH_TOKEN_KEY = "vue_turnstone_auth.refresh_token";
const REFRESH_TOKEN_PARK_KEY = "vue_turnstone_auth.impersonator.refresh_token";
export const AuthTokenService = new Vue({
  methods: {
    isImpersonating() {
      const pload = this.$auth.getPayload();
      if (!pload) {
        return false;
      }
      return !!pload.impersonation;
    },
    currentTokenValue(claim: string) {
      const pload = this.$auth.getPayload();
      if (!pload) {
        return "";
      }
      return pload[claim];
    },
    currentTokenUser() {
      const pload = this.$auth.getPayload();
      if (!pload) {
        return "";
      }
      return pload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    },
    setRefreshToken(token: string) {
      if (!!localStorage) {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
      } else if (!!sessionStorage) {
        sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
      }
    },
    parkRefreshToken() {
      const token = this.getRefreshToken();
      if (token === null) { return; }
      if (!!localStorage) {
        localStorage.setItem(REFRESH_TOKEN_PARK_KEY, token);
      } else if (!!sessionStorage) {
        sessionStorage.setItem(REFRESH_TOKEN_PARK_KEY, token);
      }
    },
    clearParkToken() {
      if (!!localStorage) {
        localStorage.removeItem(REFRESH_TOKEN_PARK_KEY);
      }
      if (!!sessionStorage) {
        sessionStorage.removeItem(REFRESH_TOKEN_PARK_KEY);
      }
    },
    clearRefreshToken() {
      if (!!localStorage) {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
      }
      if (!!sessionStorage) {
        sessionStorage.removeItem(REFRESH_TOKEN_KEY);
      }
    },
    unparkRefreshToken() {
      const token = (!!localStorage) ?
        localStorage.getItem(REFRESH_TOKEN_PARK_KEY)
        :
        (!!sessionStorage) ?
          sessionStorage.getItem(REFRESH_TOKEN_PARK_KEY)
          : null;
      this.clearParkToken();
      if (!!token) {
        this.setRefreshToken(token);
      }
    },
    getRefreshToken() {
      if (!!localStorage) {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
      }

      if (!!sessionStorage) {
        return sessionStorage.getItem(REFRESH_TOKEN_KEY);
      }
      return null;
    },
    getRemainingTokenDuration() {
      const payload = this.$auth.getPayload();
      if (!payload) {
        return moment.duration(0);
      }

      const expiryTime = moment.unix(payload.exp).utc();
      const now = moment.utc();
      const diff = moment.duration(expiryTime.diff(now));

      return diff;
    },
    getRemainingTokenSeconds() {
      return Number(
        this.getRemainingTokenDuration()
          .asSeconds()
          .toFixed(0),
      );
    },
    getRemainingTokenMinutes() {
      return this.getRemainingTokenDuration().asMinutes();
    },
    emitTokenRefreshIfNecessary() {
      const diff = this.getRemainingTokenMinutes();
      if (diff < refreshThreshold && diff > 0) {
        this.$eventHub.$emit("REFRESH_TOKEN");
      }
    },
  },
});
