import {
  UserWatch, UserWatchesResult, ProjectTrackersModel,
  ProjectModel, CompanyDetailModel,
  ProjectSearchResultModel,
  CompanySearchResultModel,
  TrackingTeamActivityModel,
} from "@/core/models";
import { HttpService } from "@/core/services";
import { TrackingNoteModel } from "@/modules/tracking/models/TrackingNoteModel";

const trackRoot: string = "fav";
export class UserTrackerModel {
  userId: number;
  fullname: string;
}

// TrackerService
export const WatcherService = WatcherServiceFactory(trackRoot);

function WatcherServiceFactory(routeRoot: string) {
  return {
    projectWatchListCache: new UserWatchesResult(),
    companyWatchListCache: new UserWatchesResult(),
    clearCache() {
      this.projectWatchListCache = new UserWatchesResult();
      this.companyWatchListCache = new UserWatchesResult();
    },
    isProjectWatched(projectId: number): boolean {
      if (!this.projectWatchListCache.watches) { return false; }
      return !!this.projectWatchListCache.watches.find(x => x.projectID === projectId);
    },
    isCompanyWatched(companyId: number): boolean {
      if (!this.companyWatchListCache.watches) { return false; }
      return !!this.companyWatchListCache.watches.find(x => x.companyID === companyId);
    },
    async getAllVisibleUsers() {
      const url = `${trackRoot}/users`;
      const res = await HttpService.getData<any[]>(url);
      return res;
    },
    async getAllUsersTracking(projectId: number, companyId: number) {
      const projectRoute = `${trackRoot}/project/${projectId}/trackers`;
      const companyRoute = `${trackRoot}/company/${companyId}/trackers`;
      const url = projectId > 0 ? `${projectRoute}` : `${companyRoute}`;
      const res = await HttpService.getData<UserTrackerModel[]>(url);
      return res;
    },
    async getAllUsersTrackingSelectedProjects(projectIds: number[]) {
      const url = `${trackRoot}/projects/trackers`;
      const res = await HttpService.postData<ProjectTrackersModel[]>(url, projectIds);
      return res;
    },
    async allProjectsNotCache(filter: any) {

    },
    async projectList(maxAmount: number) {
      // if the cache is empty, call the server.
      if (!this.projectWatchListCache
        || !this.projectWatchListCache.watches
        || this.projectWatchListCache.watches.length === 0) {
        const res = await HttpService.getData<UserWatchesResult>(
          `${routeRoot}/project/list/${maxAmount}`,
        );
        this.projectWatchListCache = res;
      }
      return this.projectWatchListCache;
    },
    async companyList(maxAmount: number) {
      if (!this.companyWatchListCache
        || !this.companyWatchListCache.watches
        || this.companyWatchListCache.watches.length === 0) {
        const res = await HttpService.getData<UserWatchesResult>(
          `${routeRoot}/company/list/${maxAmount}`,
        );
        this.companyWatchListCache = res;
      }
      return this.companyWatchListCache;
    },
    async watchProjectForUser(projectId: number, userId: number) {
      const res = await HttpService.put(`${routeRoot}/project/${projectId}/user/${userId}`);
      return res;
    },
    async trackProjectsForUser(projects: number[], userId: number) {
      const url = `${routeRoot}/project/projects/user/${userId}`;
      const res = await HttpService.postData<boolean>(url, projects);
      return res;
    },
    async watchProject(model: ProjectModel | ProjectSearchResultModel) {
      const res = await HttpService.put(`${routeRoot}/project/${model.projectId}`);
      if (res.data) {
        this.projectWatchListCache.watches!.unshift({
          projectID: Number(model.projectId),
          project: model,
        } as UserWatch);
      }
      return res;
    },
    async unwatchProjectForUser(projectId: number, userId: number) {
      const res = await HttpService.delete(`${routeRoot}/project/${projectId}/user/${userId}`);
      return res;
    },
    async unwatchProject(projectId: number) {
      const res = await HttpService.delete(`${routeRoot}/project/${projectId}`);
      if (res.data) {
        this.projectWatchListCache.watches = this.projectWatchListCache.watches!.filter(
          x => x.projectID !== projectId,
        );
      }
      return res;
    },
    async unwatchAllFilteredProjects(userId: number, tags: any[]) {
      // userId required
      if (!userId)
        return;

      let url = `${routeRoot}/project/projects?userId=${userId}`;
      if (tags.length) {
        tags.forEach(tag => {
          url += "&tags=" + tag;
        });
      }

      const res = await HttpService.delete(`${url}`);
      return res;
    },
    async watchCompanyForUser(companyId: number, userId: number) {
      const res = await HttpService.put(`${routeRoot}/company/${companyId}/user/${userId}`);
      return res;
    },
    async watchCompany(model: CompanyDetailModel | CompanySearchResultModel) {
      const res = await HttpService.put(`${routeRoot}/company/${model.companyId}`);
      if (res.data) {
        this.companyWatchListCache.watches!.unshift({
          companyID: Number(model.companyId),
          company: model,
        } as UserWatch);
      }
      return res;
    },
    async unwatchCompanyForUser(companyId: number, userId: number) {
      const res = await HttpService.delete(`${routeRoot}/company/${companyId}/user/${userId}`);
      return res;
    },
    async unwatchCompany(companyId: number) {
      const res = await HttpService.delete(`${routeRoot}/company/${companyId}`);
      if (res.data) {
        this.companyWatchListCache.watches = this.companyWatchListCache.watches!.filter(
          x => x.companyID !== companyId,
        );
      }
      return res;
    },
    async unwatchAllFilteredCompanies(userId: number, tags: any[]) {
      // userId required
      if (!userId)
        return;

      let url = `${routeRoot}/company/companies?userId=${userId}`;
      if (tags.length) {
        tags.forEach(tag => {
          url += "&tags=" + tag;
        });
      }

      const res = await HttpService.delete(`${url}`);
      return res;
    },
    async activityList() {
      const url = `${trackRoot}/activity`;
      return await HttpService.getData<TrackingTeamActivityModel[]>(url);
    },
    async upcomingRecallDates() {
      const url = `${trackRoot}/recall`;
      return await HttpService.getData<TrackingNoteModel[]>(url);
    },
    async overdueRecallDates() {
      const url = `${trackRoot}/recall/overdue`;
      return await HttpService.getData<TrackingNoteModel[]>(url);
    },
  };
}

export const UserNoteService = {
  async deleteNote(noteId: number) {
    const url = `${trackRoot}/note/${noteId}`;
    await HttpService.delete(url);
    return;
  },
  async updateNote(model: TrackingNoteModel) {
    const projectRoute = `${trackRoot}/project/${model.projectId}/note/${model.userWatchNoteId}`;
    const companyRoute = `${trackRoot}/company/${model.companyId}/note/${model.userWatchNoteId}`;
    const url = model.projectId != null && model.projectId > 0 ? `${projectRoute}` : `${companyRoute}`;
    return await HttpService.put(url, model);
  },
  async addNote(model: TrackingNoteModel) {
    const projectRoute = `${trackRoot}/project/${model.projectId}/notes`;
    const companyRoute = `${trackRoot}/company/${model.companyId}/notes`;
    const url = model.projectId != null && model.projectId > 0 ? `${projectRoute}` : `${companyRoute}`;
    return await HttpService.postData<TrackingNoteModel>(url, model);
  },
  async getNotes(projectId: number, companyId: number, maxAmount: number) {
    const max = maxAmount > 0 ? `?maxAmount=${maxAmount}` : "";
    const projectRoute = `${trackRoot}/project/${projectId}/notes`;
    const companyRoute = `${trackRoot}/company/${companyId}/notes`;
    const url = projectId > 0 ? `${projectRoute}${max}` : `${companyRoute}${max}`;
    return await HttpService.getData<TrackingNoteModel[]>(url);
  },
};