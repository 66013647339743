export const currencyFilter = (value: number) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-IE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
};