import { HttpService } from "@/core/services";
import moment, { Moment } from "moment";
import { CompanyResultsNavigationCpt } from "../components";
import { PagedCollection, PagedCollectionFilter } from "../models";
const routeRoot: string = "subscriptions";

export class SubscriptionModel {

    userProfileId: number;
    endDate?: Date | Moment | string;
    invoiceDate?: Date | Moment | string;
    invoiceId?: number;
    invoiceName: string;
    isSelfBuildRole: boolean;
    issueDescription: string;
    pubCode: string;
    startDate: Date | Moment | string;
    statusCode: string;
    statusName: string;
    subscriptionID: number;
    title: string;
    dateMigration?: Date | Moment | string;
    canBeMigrated: boolean = false;
    rateTypeId: number;
    publicationTypeId: number;
    isExportEnabled: boolean = true;
    hasCountyFilter: boolean = false;
    projectSearchAllowance: number;
    companySearchAllowance: number;
    projectAllowance: number;
    preventEmailingInvoice?: boolean;
    countyList: CountyModel[];
    netTotal: number;
    vatTotal: number;
    total: number;
    nextRolloverNetTotal: number;
    creditNotes: CreditNoteModel[];
    projectSearchStartDate?: Date | Moment | string;

    constructor() {
        this.countyList = [];
        this.creditNotes = [];
        this.vatTotal = 0;
        this.total = 0;
        this.netTotal = 0;
        this.projectAllowance = 0;
        this.projectSearchAllowance = 0;
        this.companySearchAllowance = 0;
        this.startDate = "";
        this.endDate = "";
        this.statusCode = "A";
        this.publicationTypeId = 1; // annual
        this.projectSearchStartDate = "";
    }
}

export class CreditNoteModel {
    creditNoteID: number;
    subscriptionID: number;
    creditNoteNumber: string;
    netAmount?: number | null;
    vatAmount?: number | null;
    totalAmount?: number | null;
    creditNoteDate: moment.Moment;
    justUpdated: boolean = false;
    notes: string;
    constructor() {
        this.vatAmount = null;
        this.netAmount = null;
        this.totalAmount = null;
    }
}
export class CountyModel {
    countyId: number;
    name: string;
}
export const CreditNoteService = {
    saveCreditNote(model: CreditNoteModel) {
        if (!model.subscriptionID) {
            throw new Error("subscription Id is missing from credit note, save aborted");
        }
        const saveUrl = `admin/${routeRoot}/${model.subscriptionID}/creditNote`;
        if (!model.creditNoteID) {
            return HttpService.postData<CreditNoteModel>(saveUrl, model);
        } else {
            return HttpService.putData<CreditNoteModel>(saveUrl, model);
        }
    },
};
export const SubscriptionService = {
    getAll() {
        return HttpService.getData<SubscriptionModel[]>(`${routeRoot}/all`);
    },
    addNew(model: SubscriptionModel) {
        return HttpService.postData<SubscriptionModel>(`admin/${routeRoot}`, model);
    },
    update(model: SubscriptionModel) {
        return HttpService.putData<SubscriptionModel>(`admin/${routeRoot}`, model);
    },
    downloadInvoice(subscriptionId: number) {
        return HttpService.getData<any>(`${routeRoot}/${subscriptionId}/invoice`, {
            responseType: "blob",
        });
    },
    getRolledOverSubscriptions(model: PagedCollectionFilter, date: moment.Moment | string) {
        const d = moment.utc(date);
        return HttpService.postData<PagedCollection<SubscriptionModel>>(`admin/${routeRoot}/rolledOver/${d.format("yyyy-MM-d")}`, model);
    },
    migrateUser(userId: number, isPreview: boolean) {
        return HttpService.postData<SubscriptionModel>(`admin/${routeRoot}/migrate`, {
            userId,
            isPreview,
        });
    },
};