import { CompanyDetailModel, SearchResults } from "@/core/models";
import { HttpService } from "@/core/services";
import { NumericDictionary } from "lodash";

const routeRoot: string = "alerts";
export class AlertDetailModel {
  companyId?: number;
  company?: any;
  projects: any[];
}
export class SendNotificationResult {
  status: string;
}
export class AlertModel {
  alertId: number;
  dateAlertSent: string;
  alertDetails: AlertDetailModel[];
}
export const AlertService = {
  getLatest(maxAmount: number) {
    return HttpService.getData<AlertModel[]>(`${routeRoot}/list/${maxAmount}`);
  },
  get(alertId: number) {
    return HttpService.getData<AlertModel>(`${routeRoot}/get/${alertId}`);
  },
  sendSearchAlerts(userId: number) {
    if (!userId) {
      throw { message: "Cannot send search alerts for unknown user" };
    }
    return HttpService.getData<AlertModel>(`utility/search/alerts?userId=${userId}`);

  },
  sendTrackingAlerts(userId: number) {
    if (!userId) {
      throw { message: "Cannot send tracking alerts for unknown user" };
    }
    return HttpService.getData<AlertModel>(`utility/tracking/alerts?userId=${userId}`);
  },
};
