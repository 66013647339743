import {
  SavedSearchModel,
  CISOnlineSavedSearchModel,
  SearchModel,
} from "@/core/models";
import { HttpService } from "@/core/services";
import { LatLng } from "leaflet";

const projectRoot: string = "search/project/savedSearch";
const companyRoot: string = "search/company/savedSearch";

export const SavedSearchService = {
  list(amount: number) {
    return HttpService.getData<SavedSearchModel[]>(
      `${projectRoot}/list/${amount}`,
    );
  },
  listCisOnline() {
    return HttpService.getData<CISOnlineSavedSearchModel[]>(
      `${projectRoot}/old/list`,
    );
  },
  getSingle(id: number) {
    return HttpService.getData<SearchModel>(`${projectRoot}/${id}`);
    /*
    .then(x => {
      const loc = x.fields.find(y => y.fieldName === "Location");
      if (!loc) { return x; }
      loc.filters.forEach(f => {
        if (!f.points) {
          return;
        }
        f.points = f.points.map((p: any) => ({ lat: p[0], lng: p[1] } as LatLng));
      });
      return x;
    });
    */
  },
  getSingleCisOnline(id: number) {
    return HttpService.getData<SearchModel>(`${projectRoot}/old/${id}`);
  },
  save(model: SearchModel) {
    return HttpService.postData<SavedSearchModel>(`${projectRoot}/save`, model);
  },
  update(searchId: number, model: SearchModel) {
    return HttpService.postData<SavedSearchModel>(`${projectRoot}/save/${searchId}`, model);
  },
  toggleAlert(searchId: number, value: boolean) {
    return HttpService
      .postData<SavedSearchModel>(`${projectRoot}/toggleAlert/${searchId}`,
        { isAlertEnabled: value });
  },
  delete(id: number) {
    return HttpService.delete(`${projectRoot}/${id}`);
  },
  deleteCISOnlineSearch(id: number) {
    return HttpService.delete(`${projectRoot}/old/${id}`);
  },
  renameSavedSearch(id: number, newSearchName: string) {
    const model = {
      newName: newSearchName,
    };
    return HttpService
      .postData<SavedSearchModel>(`${projectRoot}/${id}/rename`,
        model);
  },
};

export const SavedCompanySearchService = {
  list(amount: number) {
    return HttpService.getData<SavedSearchModel[]>(
      `${companyRoot}/list/${amount}`,
    );
  },
  listCisOnline() {
    return HttpService.getData<CISOnlineSavedSearchModel[]>(
      `${companyRoot}/old/list`,
    );
  },
  getSingle(id: number) {
    return HttpService.getData<SearchModel>(`${companyRoot}/${id}`);
  },
  getSingleCisOnline(id: number) {
    return HttpService.getData<SearchModel>(`${companyRoot}/old/${id}`);
  },
  save(model: SearchModel) {
    return HttpService.postData<SavedSearchModel>(`${companyRoot}/save`, model);
  },
  update(searchId: number, model: SearchModel) {
    return HttpService.postData<SavedSearchModel>(`${companyRoot}/save/${searchId}`, model);
  },
  toggleAlert(searchId: number, value: boolean) {
    return HttpService
      .postData<SavedSearchModel>(`${companyRoot}/toggleAlert/${searchId}`,
        { isAlertEnabled: value });
  },
  delete(id: number) {
    return HttpService.delete(`${companyRoot}/${id}`);
  },
  renameSavedSearch(id: number, newSearchName: string) {
    const model = {
      newName: newSearchName,
    };
    return HttpService
      .postData<SavedSearchModel>(`${projectRoot}/${id}/rename`,
        model);
  },
};
