import { HttpService } from "@/core/services";

const rootUrl: string = "forgetProject";

export class UserForgetProjectModel {
  userId: number;
  projectId: number;
  title: string;
}

export const UserForgetProjectService = {
  async exists(projectId: number) {
    return await HttpService.getData<boolean>(`${rootUrl}/${projectId}`);
  },
  async getAllForgottenProjectsForUser() {
    return await HttpService.getData<UserForgetProjectModel[]>(`${rootUrl}/all`);
  },
  async createUserForgetProject(projectId: number) {
    return await HttpService.putData(`${rootUrl}/${projectId}`, {});
  },
  async DeleteUserForgetProject(projectId: number) {
    return await HttpService.deleteData(`${rootUrl}/${projectId}`, {});
  },
};