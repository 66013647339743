import { HttpService } from "@/core/services";
import moment, { Moment } from "moment";
import { PagedCollection, PagedCollectionFilter } from "../models";
const routeRoot: string = "admin/publications";
export class SubRefPublicModel {
    title: string;
    pubCode: string;
    nlCode: string;
    canIssueInvoices: boolean;
    canCustomiseInvoice: boolean;
    companySearchAllowance: number;
    projectSearchAllowance: number;
    projectAllowance: number;
}
export class PublicationModel {
    publicationId: number;
    name: string;
    longName: string;
    issueNumber: string;
    issueDate: moment.Moment;
    product: string;
    publicationTypeId: number;
    publicationType: string;
    reportLastCreated: moment.Moment;
    updatedByStaffId: number;
    countryId: number;
    dateUpdated: moment.Moment;
    crmExportLastCreated: moment.Moment;
}
export class PublicationTypeModel {
    publicationTypeId: number;
    name: string;
    isActive: boolean;
    sortOrder: number;
}
export class RateTypeModel {
    rateTypeId: number;
    name: string;
    isActive: boolean;
    sortOrder: number;
}
export class PublicationRateModel {

    pubCode: string;
    publicationTypeId: number;
    publicationTypeName: string;
    rate: number;
    rateTypeName: string;
    rateTypeId: number;
    activeDate: string | moment.Moment;
}
export const PublicationService = {
    getAllVisiblePublications(model: PagedCollectionFilter) {
        return HttpService.postData<PagedCollection<SubRefPublicModel>>(`${routeRoot}/all/visible`, model);
    },
    getAllPublications(model: PagedCollectionFilter) {
        return HttpService.postData<PagedCollection<SubRefPublicModel>>(`${routeRoot}/all`, model);
    },
    getAllRates(model: PagedCollectionFilter) {
        return HttpService.postData<PagedCollection<RateTypeModel>>(`${routeRoot}/rateType/all`, model);
    },
    getAllPublicationTypes(model: PagedCollectionFilter) {
        return HttpService.postData<PagedCollection<PublicationTypeModel>>(`${routeRoot}/publicationType/all`, model);
    },
    getAllPublicationRates(model: PagedCollectionFilter) {
        return HttpService.postData<PagedCollection<PublicationRateModel>>(`${routeRoot}/publicationRate/all`, model);
    },
    savePublicationRate(model: PublicationRateModel) {
        return HttpService.postData<PublicationRateModel>(`${routeRoot}/publicationRate`, model);
    },
    getAllPublicationRatesForVisiblePublications(model: PagedCollectionFilter) {
        return HttpService
            .postData<PagedCollection<PublicationRateModel>>(`${routeRoot}/publicationRate/all/visible`, model);
    },
};