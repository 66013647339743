/*
 *  WARNING: Code to be cleaned.
 *
 *  This code needs to be cleaned.
 *  Unused or unknown models should be deleted.
 *  Append new stuff on the bottom.
 */

import { Validatable } from "@/core/models";
import { ProjectModel } from "./project.models";
import moment from "moment";

export class CompanyModel extends Validatable {
  projectId: number;
  companyId: number;
  name: string;
  roles: string[];
  address: string;
  phone: string;
  roleName: string;
  website: string;
  isWatched: boolean;
}

export class CompanyDetailModel extends Validatable {
  location?: number[];
  companyId: number;
  isWatched: boolean;
  name: string;
  nameAndAddress: string;
  fullAddress: string;
  emailAddress: string;
  webDomains: string;
  websiteAddress: string;
  businessType: string;
  projects: ProjectModel[];
  dateUpdated: moment.Moment;
  county: string;
  roleContact: string;
  googleLocation?: any;
  linkedInUrl: string;
}
