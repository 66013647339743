const billions = 1.0e9;
const millions = 1.0e6;
const thousands = 1.0e3;
const toFixed = (num: number, fixed: number) => {
  const re = new RegExp("^-?\\d+(?:\.\\d{0," + (fixed || -1) + "})?");
  const r = num.toString();
  const m = r.match(re);
  if (m === null) { return ""; }
  return m[0];
};

export const fromMinuteHumanReadable = (value: number) => {
  const v6 = value / 60;
  const v24 = Math.floor(v6 / 24);
  const v30 = Math.floor(v24 / 30);
  if (v30 > 0) {
    return `${v30} m ${Math.floor(v24 % v30)} d ${Math.floor(v6 % 24)} h ${Math.floor(value % 60)} min`;

  }
  if (v24 > 0) {
    return `${v24} d ${Math.floor(v6 % 24)} h ${Math.floor(value % 60)} min`;
  }
  return `${Math.floor(v6 % 24)} h ${Math.floor(value % 60)} min`;

};

export const toMoneyValue = (value: number) => {
  const absValue = Math.abs(Number(value));
  let res = Math.abs(Number(value));
  let suffix = "";
  if (absValue >= billions) {
    res = absValue / billions;
    suffix = "b";
  } else if (absValue >= millions) {
    res = absValue / millions;
    suffix = "m";
  } else if (absValue >= thousands) {
    res = absValue / thousands;
    suffix = "k";
  }
  const stringResult = toFixed(res, 1);
  if (stringResult[stringResult.length - 1] === "0") {
    return `${toFixed(res, 0)}${suffix}`;
  }
  return `${stringResult}${suffix}`;
};
