import { Vue } from "vue-property-decorator";
import { HttpService } from "@/core/services";
import {
    UserAnalyticsStatsModel,
    EditProfileModel,
    UserSessionModel,
    PagedCollectionFilter,
    PagedCollection,
} from "@/core/models";
import { Moment } from "moment";

// Service to be used with AccountAdmin role, not to be
// confused with CISAdmin role
export const AccountAdminService = new Vue({
    methods: {
        async getProfileById(profileId: number) {
            return await HttpService.getData<EditProfileModel>(`account/${profileId}`);
        },
        async getUserSession(userId: number) {
            if (!userId) { return new UserSessionModel(); }
            return await HttpService.getData<UserSessionModel>(`account/${userId}/session`);
        },
        async getStats(userId: number, daily: boolean = false, month?: number, year?: number) {
            if (!userId) { return new UserAnalyticsStatsModel(); }
            const monthParm = !!month ? `&month=${month}` : "";
            const yearParm = !!year ? `&year=${year}` : "";
            return HttpService.getData<UserAnalyticsStatsModel>(`account/${userId}/stats?daily=${daily}${monthParm}${yearParm}`);
        },
        async getUserTrackedTotals(userId: number) {
            if (!userId) { return [0, 0]; }
            return HttpService.getData<number[]>(`account/${userId}/getUserTrackedTotals`);
        },
        async getUserSavedSearches(userId: number) {
            if (!userId) { return [0, 0]; }
            return HttpService.getData<number[]>(`account/${userId}/getUserSavedSearches`);
        },
        async GetAllApiKeys(filter: PagedCollectionFilter) {
            return HttpService.postData<PagedCollection<any>>(`admin/users/apikey/list`, filter);
        },

        async GetAllUserApiKeys(userId: number) {
            return HttpService.getData<any[]>(`admin/users/${userId}/apikey/list`);
        },
        async DeactivateApiKey(userId: number, apiKeyId: number) {
            return HttpService.put(`admin/users/${userId}/apikey/${apiKeyId}/disable`);
        },
        async ActivateApiKey(userId: number, apiKeyId: number) {
            return HttpService.put(`admin/users/${userId}/apikey/${apiKeyId}/enable`);
        },
        async CreateNewApiKey(userId: number, validUntil: Moment, enableCompanySearch: boolean) {
            return HttpService.postData<any[]>(`admin/users/${userId}/apikey/new`, {
                userId,
                validUntil,
                enableCompanySearch,
            });
        },
        async UpdateApiKey(apiKeyId: number, validUntil: any, enableCompanySearch: boolean) {
            return HttpService.postData<any[]>(`admin/users/apikey/update`, {
                apiKeyId,
                validUntil,
                enableCompanySearch,
            });
        },
    },
});
