import { Vue } from "vue-property-decorator";
import axios, { AxiosRequestConfig } from "axios";

import { AuthTokenService } from ".";
import router from "@/router";
import { AccountService } from "..";

export const AxiosFactory = new Vue({
  methods: {
    createNoPragma() {
      const config: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_WEBAPI_URL,
        headers: {},
      };

      const axiosInstance = axios.create(config);
      return axiosInstance;
    },
    create() {
      const config: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_WEBAPI_URL,
        headers: {
          Pragma: "no-cache",
        },
      };

      if (this.$auth.isAuthenticated()) {
        config.headers.Authorization = `Bearer ${this.$auth.getToken()}`;
        AuthTokenService.emitTokenRefreshIfNecessary();
      }

      const axiosInstance = axios.create(config);
      axiosInstance.interceptors.response.use(
        response => response,
        async error => {
          // 422 is model validation error and we don't want any extra handling in that case
          if (error && error.response && error.response.status && error.response.status !== 422) {
            const errMessageObj: any = { title: "Error", message: "", type: "error", duration: 0, };
            switch (error.response.status) {
              case 401: {
                if (!this.$auth.isAuthenticated()) {
                  const originalRequest = error.config;
                  // we catch the refresh token explicitly to avoid showing the "refresh token error" message
                  // that is usually just the generic "username and password not valid."
                  // this prevents the double message in the top corner of the screen
                  try {

                    const refresh = await AccountService.refreshToken();
                    if (!!refresh) {
                      axiosInstance.defaults.headers.Authorization = `Bearer ${this.$auth.getToken()}`;
                      return axiosInstance(originalRequest);
                    }
                  } catch (err) {
                    // in case of error in the refresh token, we just redirect to the login;
                  }
                  if (router.currentRoute.name === "login") {
                    return Promise.reject(error);
                  }
                  router.push("/login").catch(() => { });
                } else {

                  if (!!error.response.data && !!error.response.data.error
                    && error.response.data.error === "force-logout") {
                    this.$auth.logout();
                    AuthTokenService.clearRefreshToken();
                    router.push("/loggedout").catch(() => { });
                    errMessageObj.message = "You have been logged out because another device has logged in with your account.";
                  } else {

                    errMessageObj.message = "Unauthorized action.";
                  }
                }
                break;
              }
              case 403: {
                errMessageObj.message = "Insufficient permissions.";
                break;
              }
              case 500: {
                if (error.response
                  && error.response.data
                  && error.response.data.message) {

                  if (error.response.data.message.indexOf("The token is expired") > -1) {
                    // in case of expired refresh token, the error 500 is returned.
                    // we take it from here and go to the login page
                    if (router.currentRoute.path !== "/login") {
                      router
                        .push({ path: "/login", query: router.currentRoute.query })
                        .catch(() => { });
                    }
                    return error;

                  } else {
                    errMessageObj.message = error.response.data.message;
                  }
                } else {
                  errMessageObj.message = "An error occurred.";
                  // return and do not notify
                  return error;
                }
                break;
              }

              default: {
                errMessageObj.message = "An error occurred.";
                break;
              }
            }
            this.$notify(errMessageObj);
          }

          return Promise.reject(error);
        },
      );

      return axiosInstance;
    },

  },
});
