export class ProjectMaterialModel {
  materialId: number;
  name: string;
  parentMaterialId?: number;
  parentName?: string;
  grandParentMaterialId?: number;
  grandParentName?: string;
}
export class ProjectPhotoModel {
  url: string;
  attribution: string;
}
export class ProjectModel {
  photoUrl: string;
  projectId: number;
  categories: any[];
  linksAndFiles: any[];
  materials: ProjectMaterialModel[] = [];
  photos: ProjectPhotoModel[] = [];
  companies: any[];
  title: string;
  dateUpdated: Date;
  stage: string;
  address: string;
  addressSeparated: any = {};
  value: number;
  county: string;
  planningReference: string;
  description: string;
  location: number[];
  updated: string;
  selected: boolean = false;
  isWatched: boolean = false;
  currency: string;
  researcher: any = {};
  metric: any = {};
  isLimited: boolean;
  appealStatus: string = "";
  appealStatusId?: Number;

  constructor() {
    this.categories = [];
  }
}
