import { Vue } from "vue-property-decorator";
import _ from "lodash";

import { HttpService, SubscriptionModel } from "@/core/services";
import {
  AlertSetupModel,
  LoginResponse,
  PagedCollection,
  PagedCollectionFilter,
  UserAnalyticsStatsModel,
  EditProfileModel,
  UserSessionModel,
} from "@/core/models";
import { SearchService } from "./search.service";
import { AuthTokenService } from "./common";
import { zohoManager } from "@/zoho/zoho";
import moment, { Moment } from "moment";

export class ErrorMessageModel {
  statusCode: string;
  message: string;
}
export class RestorePasswordRequestModel {
  email: string;
  newPassword: string;
  confirmPassword: string;
  token: string;
}

export class AccountModel {
  company: string;
  businessType: number;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  postCode: string;
  county: string;
  country: string;
  profile: ProfileModel;
  constructor() {
    this.company = "";
    this.businessType = 0;
    this.address1 = "";
    this.address2 = "";
    this.address3 = "";
    this.county = "";
    this.postCode = "";
    this.country = "EU_IE";
    this.profile = new ProfileModel();
  }
}

export class SessionRowModel {
  loginLogoutId: number;
  sessionId: string;
  machineGuid: string;
  loginDateTime: string;
  minutesOnline: number;
  userId: number;
  userProfileId: number;
  username: string;
  email: string;
  fullName: string;
  lastRequestDateTime: string;
  isOverLappingLogin: boolean;
  logoutDateTime: string;
  doForceLogout: boolean;
  accountCode: string;
  ipAddress: string;
  browserAgent: string;
}
export class ProfileModel {
  accountEmail: string;
  isActive: boolean;
  userId: number;
  title: string;
  name: string;
  lastname: string;
  email: string;
  mobile: string;
  lastActivityAt: string;
  invited: boolean;
  accountCode: string;
  hasAcceptedTerms: boolean;
  emailConfirmed: boolean;
  vatCode: number;
  userProfileId: number;
  loginEmail: string;
  defaultView: number;
}

export class ChangePasswordModel {
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export class UserSearchPagedCollectionFilter extends PagedCollectionFilter {
  pubCode: string;
  status: string;
  search: string;
}

export class TrackingPagedCollectionFilter extends PagedCollectionFilter {
  filterUntracked: boolean;
  selectedTags: number[];
  selectedUser: number;
}

export class ConfirmEmailAddressModel {
  token: any;
  email: string;
}
export interface ITokenObject {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}
export class TokenPayload {
  AccountCode: string;
  CompanyAllowance: string;
  CompanySearchAllowance: string;
  EmailHash: string;
  IsCountyFilterSub: string;
  IsMajorProjects: string;
  IsExportEnabled: string;
  IsSelfBuild: string;
  MachineGuid: string;
  ProjectAllowance: string;
  ProjectSearchAllowance: string;
  SubscriptionId: string[];
  aud: string;
  exp: number;
  // http://schemas.microsoft.com/ws/2008/06/identity/claims/role: string;
  //   http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress: string;
  // http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name: string;
  //   http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier: string;
  impersonation: string;
  iss: string;
  nbf: number;
}

const RoleKey = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
interface IProfileHolder {
  currentProfile?: ProfileModel;
}
export const CurrentProfile: IProfileHolder = {
  currentProfile: undefined,
};
export const AccountService = new Vue({
  methods: {
    async autologin(loginKey: string) {
      SearchService.clearCache();
      return await HttpService.getData<LoginResponse>(
        `auth/autologin/${loginKey}`,
      );
    },
    async login(model: any) {
      CurrentProfile.currentProfile = undefined;
      SearchService.clearCache();
      return await HttpService.postData<LoginResponse>("auth/login", model);
    },
    async forcelogin(salesNotification: boolean = false) {
      CurrentProfile.currentProfile = undefined;
      SearchService.clearCache();
      return await HttpService.postData<LoginResponse>("auth/login/force", { salesNotification });
    },
    async logout() {
      if (this.$auth.isAuthenticated()) {
        await HttpService.get(`auth/logout`);
        this.$auth.logout();
      }
      zohoManager.UpdateUser("");
      AuthTokenService.clearRefreshToken();

      SearchService.clearCache();
      this.$eventHub.$emit("USER_UPDATED");
    },
    async refreshToken() {
      const currentRefreshToken = AuthTokenService.getRefreshToken();

      if (!currentRefreshToken) {
        return null;
      }
      const response = await HttpService.postData<LoginResponse>("auth/refreshToken", {
        refreshToken: currentRefreshToken,
      });
      if (!!response) {
        this.setToken(response.token);
      }
      return response;
    },
    saveToken() {
      AuthTokenService.parkRefreshToken();
    },
    restoreToken() {
      AuthTokenService.unparkRefreshToken();
      this.refreshToken();
    },
    setToken(tokenResponse: ITokenObject) {
      this.$auth.setToken(tokenResponse);
      AuthTokenService.setRefreshToken(tokenResponse.refresh_token);
      this.$eventHub.$emit("USER_UPDATED");
    },
    async whousedit() {
      return await HttpService.getData<PagedCollection<SessionRowModel>>(`admin/sessions/usage`);
    },
    async whosonline() {
      return await HttpService.getData<PagedCollection<SessionRowModel>>(`admin/sessions/whosonline`);
    },
    async closeAll() {
      return await HttpService.get(`admin/sessions/closeall`);
    },
    async close(machineGuid: string) {
      return await HttpService.get(`admin/sessions/close/${machineGuid}`);
    },
    async getProfile() {
      CurrentProfile.currentProfile = await HttpService.getData<ProfileModel>("account");
      return CurrentProfile.currentProfile;
    },
    async getProfileById(profileId: number) {
      return await HttpService.getData<EditProfileModel>(`admin/users/${profileId}`);
    },
    async getUserSubscriptions(profileId: number) {
      return await HttpService.getData<SubscriptionModel[]>(`admin/users/${profileId}/subscriptions`);
    },
    async getUserSession(userId: number) {
      if (!userId) { return new UserSessionModel(); }
      return await HttpService.getData<UserSessionModel>(`admin/users/${userId}/session`);
    },
    async SaveUser(model: ProfileModel) {
      return await HttpService.postData<ProfileModel>("account", model);
    },
    async SaveNewUser(model: EditProfileModel) {
      return await HttpService.postData<EditProfileModel>("admin/users/create", model);
    },
    async UpdateUser(model: EditProfileModel) {
      return await HttpService.putData<EditProfileModel>("admin/users/update", model);
    },
    async ActivateAccount(model: ProfileModel) {
      return await HttpService.put("admin/users/activate", model);
    },
    async DeactivateAccount(model: ProfileModel) {
      return await HttpService.put("admin/users/deactivate", model);
    },
    async ConfirmEmailAccount(model: ProfileModel) {
      return await HttpService.put("admin/users/confirmEmail", model);
    },
    async UnconfirmEmailAccount(model: ProfileModel) {
      return await HttpService.put("admin/users/unconfirmEmail", model);
    },
    async SendInvitation(userProfileId: number) {
      const response = await HttpService.postData<boolean>(`admin/users/${userProfileId}/sendInvite`, {});
      return response;
    },
    // cis administrator specific
    async getStats(userId: number, daily: boolean = false, month?: number, year?: number) {
      if (!userId) { return new UserAnalyticsStatsModel(); }
      const monthParm = !!month ? `&month=${month}` : "";
      const yearParm = !!year ? `&year=${year}` : "";
      return HttpService.getData<UserAnalyticsStatsModel>(`admin/users/${userId}/stats?daily=${daily}${monthParm}${yearParm}`);
    },
    async getUserTrackedTotals(userId: number) {
      if (!userId) { return [0, 0]; }
      return HttpService.getData<number[]>(`admin/users/${userId}/getUserTrackedTotals`);
    },
    async getUserSavedSearches(userId: number) {
      if (!userId) { return [0, 0]; }
      return HttpService.getData<number[]>(`admin/users/${userId}/getUserSavedSearches`);
    },
    async ImpersonateAccount(userProfileId: number) {
      SearchService.clearCache();
      const response = await HttpService.postData<LoginResponse>("admin/users/impersonate", { userProfileId });
      if (!!response) {
        this.saveToken();
        this.setToken(response.token);
      }
      return response;
    },
    async SearchUser(model: UserSearchPagedCollectionFilter) {
      return await HttpService.postData<PagedCollection<ProfileModel>>("admin/users/search", model);
    },
    async SetPassword(userId: number, model: RestorePasswordRequestModel) {
      return HttpService.postData<LoginResponse>(`admin/users/${userId}/password/set`, model);
    },
    async SaveCompany(model: ProfileModel) {
      return await HttpService.postData<ProfileModel>("account/company", model);
    },
    async getCompanyAccounts() {
      return await HttpService.getData<ProfileModel[]>("account/company/accounts");
    },
    async AddRole(userId: number, role: string) {
      return await HttpService
        .postData<EditProfileModel>(`admin/users/${userId}/roles/add`, { role });
    },
    async RevokeRole(userId: number, role: string) {
      return await HttpService
        .postData<EditProfileModel>(`admin/users/${userId}/roles/revoke`, { role });
    },
    acceptCisTerms() {
      return HttpService.post(`account/acceptTerms`);
    },
    setDefaultView(userProfileId: number, defaultViewId: number) {
      return HttpService.post(`account/${userProfileId}/setDefaultView/${defaultViewId}`);
    },
    unlock(userId: number) {
      return HttpService.put(`account/unlock/${userId}`);
    },
    confirmEmailAddress(code: string, email: string) {
      return HttpService.postData<ConfirmEmailAddressModel>(`auth/confirmEmail`, { token: code, email });
    },
    restorePassword(model: RestorePasswordRequestModel) {
      return HttpService.postData<LoginResponse>(`auth/forgotPassword/`, model);
    },
    resetPassword(model: any) {
      return HttpService.post(`auth/forgotPassword/request`, { email: model.email });
    },
    changePassword(model: ChangePasswordModel) {
      return HttpService.postData<ChangePasswordModel>(`auth/changePassword`, model);
    },
    isPrivacyPolicyAccepted() {
      return HttpService.getData<boolean>(`account/isPrivacyPolicyAccepted`);
    },

    acceptPrivacyPolicy() {
      return HttpService.put(`account/acceptPrivacyPolicy`);
    },
    hasStartedQuestionnaire() {
      return HttpService.getData<boolean>(`account/hasStartedQuestionnaire`);
    },
    startQuestionnaire() {
      return HttpService.put(`account/startQuestionnaire`);
    },
    isAttendanceRecorded() {
      return HttpService.getData<boolean>(`account/isAttendanceRecorded`);
    },
    getUserAlertSettings() {
      return HttpService.getData<AlertSetupModel>(`account/settings`);
    },
    setUserAlertSettings(model: AlertSetupModel) {
      return HttpService.postData<AlertSetupModel>(`account/settings`, model);
    },
    getMachineCode() {
      const token: TokenPayload = this.$auth.getPayload();
      if (!token) { return ""; }
      if (!!token.impersonation) {
        return `impersonating-${token.impersonation}`;
      }
      return token.MachineGuid;
    },
    getSubscriptionType() {
      const token: TokenPayload = this.$auth.getPayload();
      if (!token) {
        const ad = this.isAdmin();
        return {
          IsSelfBuild: ad,
          IsMajorProjects: ad,
        };
      }
      return {
        IsSelfBuild: token.IsSelfBuild,
        IsMajorProjects: token.IsMajorProjects,
        IsExportEnabled: token.IsExportEnabled === "True",
      };
    },
    isInRole(roleName: string) {
      const token = this.$auth.getPayload();
      if (!token || !token[RoleKey]) {
        return false;
      }
      return _.includes(token[RoleKey], roleName);
    },

    isAdmin() {
      return this.isInRole("admin");
    },
    getCountriesDropdown() {
      return [
        { code: "EU_IE", name: "Ireland" },
        { code: "EU_GB", name: "United Kingdom" },
        { code: "AF", name: "Afghanistan" },
        { code: "AL", name: "Albania" },
        { code: "DZ", name: "Algeria" },
        { code: "AS", name: "American Samoa" },
        { code: "AD", name: "Andorra" },
        { code: "AO", name: "Angola" },
        { code: "AI", name: "Anguilla" },
        { code: "AQ", name: "Antarctica" },
        { code: "AG", name: "Antigua And Barbuda" },
        { code: "AR", name: "Argentina" },
        { code: "AM", name: "Armenia" },
        { code: "AW", name: "Aruba" },
        { code: "AU", name: "Australia" },
        { code: "EU_AT", name: "Austria" },
        { code: "AZ", name: "Azerbaijan" },
        { code: "BS", name: "Bahamas" },
        { code: "BH", name: "Bahrain" },
        { code: "BD", name: "Bangladesh" },
        { code: "BB", name: "Barbados" },
        { code: "BY", name: "Belarus" },
        { code: "EU_BE", name: "Belgium" },
        { code: "BZ", name: "Belize" },
        { code: "BJ", name: "Benin" },
        { code: "BM", name: "Bermuda" },
        { code: "BT", name: "Bhutan" },
        { code: "BO", name: "Bolivia" },
        { code: "BA", name: "Bosnia And Herzegowina" },
        { code: "BW", name: "Botswana" },
        { code: "BV", name: "Bouvet Island" },
        { code: "BR", name: "Brazil" },
        { code: "IO", name: "British Indian Ocean Territory" },
        { code: "BN", name: "Brunei Darussalam" },
        { code: "EU_BG", name: "Bulgaria" },
        { code: "BF", name: "Burkina Faso" },
        { code: "BI", name: "Burundi" },
        { code: "KH", name: "Cambodia" },
        { code: "CM", name: "Cameroon" },
        { code: "CA", name: "Canada" },
        { code: "CV", name: "Cape Verde" },
        { code: "KY", name: "Cayman Islands" },
        { code: "CF", name: "Central African Republic" },
        { code: "TD", name: "Chad" },
        { code: "CL", name: "Chile" },
        { code: "CN", name: "China" },
        { code: "CX", name: "Christmas Island" },
        { code: "CC", name: "Cocos (Keeling) Islands" },
        { code: "CO", name: "Colombia" },
        { code: "KM", name: "Comoros" },
        { code: "CG", name: "Congo" },
        { code: "CK", name: "Cook Islands" },
        { code: "CR", name: "Costa Rica" },
        { code: "CI", name: "Cote D'Ivoire" },
        { code: "HR", name: "Croatia (Local Name: Hrvatska)" },
        { code: "CU", name: "Cuba" },
        { code: "EU_CY", name: "Cyprus" },
        { code: "EU_CZ", name: "Czech Republic" },
        { code: "EU_DK", name: "Denmark" },
        { code: "DJ", name: "Djibouti" },
        { code: "DM", name: "Dominica" },
        { code: "DO", name: "Dominican Republic" },
        { code: "TP", name: "East Timor" },
        { code: "EC", name: "Ecuador" },
        { code: "EG", name: "Egypt" },
        { code: "SV", name: "El Salvador" },
        { code: "GQ", name: "Equatorial Guinea" },
        { code: "ER", name: "Eritrea" },
        { code: "EU_EE", name: "Estonia" },
        { code: "ET", name: "Ethiopia" },
        { code: "FK", name: "Falkland Islands (Malvinas)" },
        { code: "FO", name: "Faroe Islands" },
        { code: "FJ", name: "Fiji" },
        { code: "EU_FI", name: "Finland" },
        { code: "EU_FR", name: "France" },
        { code: "GF", name: "French Guiana" },
        { code: "PF", name: "French Polynesia" },
        { code: "TF", name: "French Southern Territories" },
        { code: "GA", name: "Gabon" },
        { code: "GM", name: "Gambia" },
        { code: "GE", name: "Georgia" },
        { code: "EU_DE", name: "Germany" },
        { code: "GH", name: "Ghana" },
        { code: "GI", name: "Gibraltar" },
        { code: "EU_GR", name: "Greece" },
        { code: "GL", name: "Greenland" },
        { code: "GD", name: "Grenada" },
        { code: "GP", name: "Guadeloupe" },
        { code: "GU", name: "Guam" },
        { code: "GT", name: "Guatemala" },
        { code: "GN", name: "Guinea" },
        { code: "GW", name: "Guinea-Bissau" },
        { code: "GY", name: "Guyana" },
        { code: "HT", name: "Haiti" },
        { code: "HM", name: "Heard And Mc Donald Islands" },
        { code: "VA", name: "Holy See (Vatican City State)" },
        { code: "HN", name: "Honduras" },
        { code: "HK", name: "Hong Kong" },
        { code: "EU_HU", name: "Hungary" },
        { code: "IS", name: "Icel And" },
        { code: "IN", name: "India" },
        { code: "ID", name: "Indonesia" },
        { code: "IR", name: "Iran (Islamic Republic Of)" },
        { code: "IQ", name: "Iraq" },
        { code: "IL", name: "Israel" },
        { code: "EU_IT", name: "Italy" },
        { code: "JM", name: "Jamaica" },
        { code: "JP", name: "Japan" },
        { code: "JO", name: "Jordan" },
        { code: "KZ", name: "Kazakhstan" },
        { code: "KE", name: "Kenya" },
        { code: "KI", name: "Kiribati" },
        { code: "KP", name: "Korea, Dem People's Republic" },
        { code: "KR", name: "Korea, Republic Of" },
        { code: "KW", name: "Kuwait" },
        { code: "KG", name: "Kyrgyzstan" },
        { code: "LA", name: "Lao People's Dem Republic" },
        { code: "EU_LV", name: "Latvia" },
        { code: "LB", name: "Lebanon" },
        { code: "LS", name: "Lesotho" },
        { code: "LR", name: "Liberia" },
        { code: "LY", name: "Libyan Arab Jamahiriya" },
        { code: "LI", name: "Liechtenstein" },
        { code: "EU_LT", name: "Lithuania" },
        { code: "EU_LU", name: "Luxembourg" },
        { code: "MO", name: "Macau" },
        { code: "MK", name: "Macedonia" },
        { code: "MG", name: "Madagascar" },
        { code: "MW", name: "Malawi" },
        { code: "MY", name: "Malaysia" },
        { code: "MV", name: "Maldives" },
        { code: "ML", name: "Mali" },
        { code: "EU_MT", name: "Malta" },
        { code: "MH", name: "Marshall Islands" },
        { code: "MQ", name: "Martinique" },
        { code: "MR", name: "Mauritania" },
        { code: "MU", name: "Mauritius" },
        { code: "YT", name: "Mayotte" },
        { code: "MX", name: "Mexico" },
        { code: "FM", name: "Micronesia, Federated States" },
        { code: "MD", name: "Moldova, Republic Of" },
        { code: "MC", name: "Monaco" },
        { code: "MN", name: "Mongolia" },
        { code: "MS", name: "Montserrat" },
        { code: "MA", name: "Morocco" },
        { code: "MZ", name: "Mozambique" },
        { code: "MM", name: "Myanmar" },
        { code: "NA", name: "Namibia" },
        { code: "NR", name: "Nauru" },
        { code: "NP", name: "Nepal" },
        { code: "EU_NL", name: "Netherlands" },
        { code: "AN", name: "Netherlands Ant Illes" },
        { code: "NC", name: "New Caledonia" },
        { code: "NZ", name: "New Zealand" },
        { code: "NI", name: "Nicaragua" },
        { code: "NE", name: "Niger" },
        { code: "NG", name: "Nigeria" },
        { code: "NU", name: "Niue" },
        { code: "NF", name: "Norfolk Island" },
        { code: "MP", name: "Northern Mariana Islands" },
        { code: "NO", name: "Norway" },
        { code: "OM", name: "Oman" },
        { code: "PK", name: "Pakistan" },
        { code: "PW", name: "Palau" },
        { code: "PA", name: "Panama" },
        { code: "PG", name: "Papua New Guinea" },
        { code: "PY", name: "Paraguay" },
        { code: "PE", name: "Peru" },
        { code: "PH", name: "Philippines" },
        { code: "PN", name: "Pitcairn" },
        { code: "EU_PL", name: "Poland" },
        { code: "EU_PT", name: "Portugal" },
        { code: "PR", name: "Puerto Rico" },
        { code: "QA", name: "Qatar" },
        { code: "RE", name: "Reunion" },
        { code: "EU_RO", name: "Romania" },
        { code: "RU", name: "Russian Federation" },
        { code: "RW", name: "Rwanda" },
        { code: "KN", name: "Saint K Itts And Nevis" },
        { code: "LC", name: "Saint Lucia" },
        { code: "VC", name: "Saint Vincent, The Grenadines" },
        { code: "WS", name: "Samoa" },
        { code: "SM", name: "San Marino" },
        { code: "ST", name: "Sao Tome And Principe" },
        { code: "SA", name: "Saudi Arabia" },
        { code: "SN", name: "Senegal" },
        { code: "SC", name: "Seychelles" },
        { code: "SL", name: "Sierra Leone" },
        { code: "SG", name: "Singapore" },
        { code: "EU_SK", name: "Slovakia (Slovak Republic)" },
        { code: "EU_SI", name: "Slovenia" },
        { code: "SB", name: "Solomon Islands" },
        { code: "SO", name: "Somalia" },
        { code: "ZA", name: "South Africa" },
        { code: "GS", name: "South Georgia , S Sandwich Is." },
        { code: "EU_ES", name: "Spain" },
        { code: "LK", name: "Sri Lanka" },
        { code: "SH", name: "St. Helena" },
        { code: "PM", name: "St. Pierre And Miquelon" },
        { code: "SD", name: "Sudan" },
        { code: "SR", name: "Suriname" },
        { code: "SJ", name: "Svalbard, Jan Mayen Islands" },
        { code: "SZ", name: "Sw Aziland" },
        { code: "EU_SE", name: "Sweden" },
        { code: "CH", name: "Switzerland" },
        { code: "SY", name: "Syrian Arab Republic" },
        { code: "TW", name: "Taiwan" },
        { code: "TJ", name: "Tajikistan" },
        { code: "TZ", name: "Tanzania, United Republic Of" },
        { code: "TH", name: "Thailand" },
        { code: "TG", name: "Togo" },
        { code: "TK", name: "Tokelau" },
        { code: "TO", name: "Tonga" },
        { code: "TT", name: "Trinidad And Tobago" },
        { code: "TN", name: "Tunisia" },
        { code: "TR", name: "Turkey" },
        { code: "TM", name: "Turkmenistan" },
        { code: "TC", name: "Turks And Caicos Islands" },
        { code: "TV", name: "Tuvalu" },
        { code: "UG", name: "Uganda" },
        { code: "UA", name: "Ukraine" },
        { code: "AE", name: "United Arab Emirates" },
        { code: "US", name: "United States" },
        { code: "UM", name: "United States Minor Is." },
        { code: "UY", name: "Uruguay" },
        { code: "UZ", name: "Uzbekistan" },
        { code: "VU", name: "Vanuatu" },
        { code: "VE", name: "Venezuela" },
        { code: "VN", name: "Viet Nam" },
        { code: "VG", name: "Virgin Islands (British)" },
        { code: "VI", name: "Virgin Islands (U.S.)" },
        { code: "WF", name: "Wallis And Futuna Islands" },
        { code: "EH", name: "Western Sahara" },
        { code: "YE", name: "Yemen" },
        { code: "YU", name: "Yugoslavia" },
        { code: "ZR", name: "Zaire" },
        { code: "ZM", name: "Zambia" },
        { code: "ZW", name: "Zimbabwe" },
      ];
    },
    getCountiesDropdown() {
      return [
        { code: "51", name: "-" },
        { code: "81", name: "Blackrock, Co. Dublin" },
        { code: "67", name: "Co. Antrim" },
        { code: "65", name: "Co. Armagh" },
        { code: "05", name: "Co. Carlow" },
        { code: "04", name: "Co. Cavan" },
        { code: "02", name: "Co. Clare" },
        { code: "03", name: "Co. Cork" },
        { code: "70", name: "Co. Derry" },
        { code: "17", name: "Co. Donegal" },
        { code: "66", name: "Co. Down" },
        { code: "16", name: "Co. Dublin" },
        { code: "79", name: "Co. Fermanagh" },
        { code: "19", name: "Co. Galway" },
        { code: "24", name: "Co. Kerry" },
        { code: "22", name: "Co. Kildare" },
        { code: "23", name: "Co. Kilkenny" },
        { code: "30", name: "Co. Laois" },
        { code: "29", name: "Co. Leitrim" },
        { code: "28", name: "Co. Limerick" },
        { code: "25", name: "Co. Longford" },
        { code: "27", name: "Co. Louth" },
        { code: "33", name: "Co. Mayo" },
        { code: "31", name: "Co. Meath" },
        { code: "32", name: "Co. Monaghan" },
        { code: "36", name: "Co. Offaly" },
        { code: "38", name: "Co. Roscommon" },
        { code: "40", name: "Co. Sligo" },
        { code: "76", name: "Co. Tipperary" },
        { code: "68", name: "Co. Tyrone" },
        { code: "47", name: "Co. Waterford" },
        { code: "48", name: "Co. Westmeath" },
        { code: "50", name: "Co. Wexford" },
        { code: "49", name: "Co. Wicklow" },
        { code: "01", name: "Cork City" },
        { code: "07", name: "Dublin 1" },
        { code: "52", name: "Dublin 10" },
        { code: "53", name: "Dublin 11" },
        { code: "54", name: "Dublin 12" },
        { code: "55", name: "Dublin 13" },
        { code: "56", name: "Dublin 14" },
        { code: "57", name: "Dublin 15" },
        { code: "58", name: "Dublin 16" },
        { code: "59", name: "Dublin 17" },
        { code: "60", name: "Dublin 18" },
        { code: "08", name: "Dublin 2" },
        { code: "62", name: "Dublin 20" },
        { code: "63", name: "Dublin 22" },
        { code: "64", name: "Dublin 24" },
        { code: "09", name: "Dublin 3" },
        { code: "10", name: "Dublin 4" },
        { code: "11", name: "Dublin 5" },
        { code: "12", name: "Dublin 6" },
        { code: "86", name: "Dublin 6W" },
        { code: "13", name: "Dublin 7" },
        { code: "14", name: "Dublin 8" },
        { code: "15", name: "Dublin 9" },
        { code: "82", name: "Dun Laoghaire, Co. Dublin" },
        { code: "78", name: "Galway City" },
        { code: "87", name: "Limerick City" },
        { code: "83", name: "Lucan, Co. Dublin" },
        { code: "84", name: "Malahide, Co. Dublin" },
        { code: "88", name: "Portmarnock, Co. Dublin" },
        { code: "85", name: "Swords, Co. Dublin" },
        { code: "89", name: "Co. Kildare" },
        { code: "90", name: "Co. Wicklow" },
      ];
    },
    getBusinessTypesDropdown() {
      return [
        { code: 1, name: "Financial" },
        { code: 3, name: "Suppliers" },
        { code: 16, name: "Interior" },
        { code: 22, name: "Contractor" },
        { code: 27, name: "Technology" },
        { code: 29, name: "Transport" },
        { code: 32, name: "Miscellaneous" },
        { code: 34, name: "Hospitality" },
        { code: 42, name: "Cleaning" },
        { code: 63, name: "Government" },
        { code: 105, name: "Utilities" },
        { code: 124, name: "Medical" },
        { code: 152, name: "Publishing" },
        { code: 235, name: "Safety" },
        { code: 323, name: "Professional" },
        { code: 343, name: "Test" },
        { code: 344, name: "Services" },
      ];
    },
  },
});
