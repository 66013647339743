import { ProjectModel, CompanyDetailModel, CompanyModel } from ".";
import moment from "moment";

export class UserWatchesResult {
  watches: UserWatch[];
  total: number;
  constructor() {
    this.watches = [];
  }
}

export class ProjectTrackersModel {
  projectId: number;
  usersTracking: UserTrackingModel[];
}

export class UserTrackingModel {
  userId: number;
  fullname: string;
}

export class UserWatch {
  userID: number;
  projectID: number;
  companyID: number;
  project: ProjectModel;
  company: CompanyDetailModel;
  userWatchID: number;
  dateCreated: Date;
}

export class TrackingTeamActivityModel {
  userID: number;
  name: string;
  avatarUrl: string;
  activityDate: moment.Moment;
  note: string;
  recallDate?: Date;
  project?: ProjectModel;
  company?: CompanyDetailModel;
  isRemove: boolean;
}

export class Category {
  sector: string;
  category: string;
}

export class CompanyElement {
  companyId: number;
  name: string;
  phone: string;
  address: string;
  website: string;
  roleName: string;
}

export class History { }

export class LinksAndFile {
  url: string;
  name: string;
}
