export const b$ = (value: string, newtag: string) => {
  if (!value) {
    return value;
  }
  const regex: RegExp = /[^:]*/;
  const paragraphs = value.split(/\n/);
  const tag = newtag || "strong";
  return paragraphs
    .map(p => {
      const res = regex.test(p);
      if (res) {
        const matches = regex.exec(p);
        if (!matches) {
          return p;
        }
        if (matches[0].length >= p.length || matches[0].length > 50) {
          return p;
        }
        return p.replace(`${matches[0]}:`, `<${tag}>${matches[0]}:</${tag}>`);
      }
      return p;
    })
    .join("<br/>");
};
