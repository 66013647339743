import { Route } from "vue-router";
import { takeWhile } from "lodash";

export class RouteWrapper {
    route: Route;
    title: string;
}

class RouteStack {
    routeStack: RouteWrapper[] = [];
    addTitle(route: Route, title: string) {
        const found = this.routeStack.filter(x => x.route.path === route.path);
        if (found) {
            found.forEach(x => {
                x.title = title;
            });
        }
    }

    push(route: Route) {
        if (!route.meta.breadCrumb) { return; }
        const routeFound = this.routeStack.find(x => x.route.path === route.path);
        let t = "";
        if (routeFound) {
            t = routeFound.title;
        }
        this.trimTo(route);
        this.routeStack.push({ route, title: t });
    }
    pop() {
        return this.routeStack.pop();
    }
    clear() {
        while (this.routeStack.length > 0) { this.routeStack.pop(); }
    }
    getRoutes() {
        return this.routeStack;
    }
    trimTo(route: Route) {
        const routeFound = this.routeStack.find(x => x.route.path === route.path);
        if (routeFound) {
            const idx = this.routeStack.indexOf(routeFound);
            while (this.routeStack.length > idx) {
                this.routeStack.pop();
            }
        }
    }
}

export const routeStack = new RouteStack();
