import { DashboardModel } from "@/core/models";
import { HttpService } from "@/core/services";

const routeRoot: string = "dashboard";

export const DashboardService = {
    stats() {
        return HttpService.getData<DashboardModel>(
            `${routeRoot}/stats`);
    },
};
