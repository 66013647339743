import { ProjectModel } from "@/core/models";
import { HttpService } from "@/core/services";

const routeRoot: string = "project";

export const ProjectService = {
  getHighlightedProjects(numberOfProjects: number) {
    return HttpService.getData<ProjectModel[]>(`${routeRoot}/highlighted/${numberOfProjects}`);
  },
  getProjectDetails(projectId: number) {
    return HttpService.getData<ProjectModel>(`${routeRoot}/${projectId}`);
  },
  getProjectHistory(projectId: number) {
    return HttpService.getData<any>(`${routeRoot}/${projectId}/history`);
  },
  exportProject(projectId: number) {
    return HttpService.getData<any>(`${routeRoot}/${projectId}/export`, {
      responseType: "blob",
    });
  },
  isProject(projectId: number) {
    return HttpService.getData<boolean>(`${routeRoot}/${projectId}/exists`);
  },
  getAnonymousProjectDetails(projectId: number) {
    return HttpService.getData<ProjectModel>(`${routeRoot}/anon/${projectId}`);
  },
  peek(projectId: number | string) {
    return HttpService.get(`${routeRoot}/${projectId}/peek`);
  },
};
