import _ from "lodash";

import { SimpleLookupModel } from "@/core/models";

export const LookupsService = {
  findLookup(lookups: SimpleLookupModel[], lookupId: number) {
    return _.find(lookups, { id: lookupId });
  },
  findLookupId(lookups: SimpleLookupModel[], text: string) {
    const lookup = _.find(lookups, { text });
    return lookup ? lookup.id : null;
  },
  findLookupText(lookups: SimpleLookupModel[], lookupId: number): string {
    return this.findLookupProperty(lookups, lookupId, "text");
  },
  findLookupName(lookups: SimpleLookupModel[], lookupId: number): string {
    return this.findLookupProperty(lookups, lookupId, "name");
  },
  findLookupProperty(lookups: any[], lookupId: number, propName: string): any {
    const lookup = this.findLookup(lookups, lookupId) as any;
    return lookup ? lookup[propName as any] : null;
  },
};
