import { Validatable } from "@/core/models";
import moment from "moment";

export class UserCollectionModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  emailConfirmed: boolean;
  roles: string[];
}

export class RoleModel {
  id: number;
  name: string;
}

export class UserAccountModel extends Validatable {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  fullName: string;
  // NOTE: Change to roleIds at some point if we start
  // allowing users to be in multiple roles
  roleId: number;
}

export class UserSearchModel {
  userId?: number;
  fullName: string;
  email: string;
}

export class AttendeeModel {
  attendeeName: string;
  attendeeEmail: string;
}

export class AlertSetupModel {
  userId: number;
  setupId: number;
  alertFrequencyId: number;
  trackingAlertFrequencyId: number;
  selfBuildAlertFrequencyId: number;
  selfBuildCrmAlertFrequencyId: number;
  alertFormatId: number;
  isActive: boolean;
  isAlertSentViaSms: boolean;
  isAlertSentViaEmail: boolean;
}

export class LookupCodeModel {
  code: string | number;
  name: string;
}

export class DateValue {
  value: number;
  date: number;
}

export class UserAnalyticsStatsModel {
  projectSearchInteractions: DateValue[];
  companySearchInteractions: DateValue[];
  projectTrackedInteractions: DateValue[];
  companyTrackedInteractions: DateValue[];
  projectInteractions: DateValue[];
  companyInteractions: DateValue[];
  companyPeekInteractions: DateValue[];
  projectPeekInteractions: DateValue[];
  constructor() {

  }
}

export class EditProfileModel {
  percentage: number;
  accountCode: string;
  accountEmail: string;
  accountName: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  postCode: string;
  county: string;
  businessType: string;
  businessTypeId: number;
  country: string;
  countyCode: string;
  emailAddress: string;
  hasCurrentPayingSubscription: boolean;
  isActive: boolean;
  lastname: string;
  mobile: string;
  name: string;
  subscriptions: any[];
  title: string;
  userProfileId: number;
  projectAccessLeft: number;
  projectSearchAllowance: number;
  companySearchAllowance: number;
  searchCompanyLeft: number;
  searchProjectLeft: number;
  projectAccessAllowance: number;
  companyAccessAllowance: number;
  totalCompanyAccess: number;
  totalCompanyExport: number;
  totalCompanySearch: number;
  totalProjectAccess: number;
  totalProjectExport: number;
  totalProjectSearch: number;
  roles: string[];
  userId: number;
  vatNumber: string;
  vatCode: number;
  vatExempt: boolean;
  invoiceInSterling: boolean;
  loginEmail: string;
  totalForgottenProjects: number;
  forgottenProjectsAllowance: number;

  constructor() {
    this.country = "EU_IE";
    this.invoiceInSterling = false;
    this.vatExempt = false;
    this.accountCode = "";
    this.accountEmail = "";
    this.accountName = "";
  }
}

export class UserSessionModel {
  LoginDateTime: string;
  LogoutDateTime: string;
  MinutesOnline: number;
  LastRequestDateTime: string;
}