import { DashboardModel } from "@/core/models";
import { HttpService } from "@/core/services";

const routeRoot: string = "config";
export class InterfaceConfiguration {
    isLoaded: boolean = false;
    isCompanySearchEnabled: boolean;
}
const cache = new InterfaceConfiguration();

export const ConfigurationService = {
    configCache: cache,
    async getInterfaceConfig() {
        if (!cache.isLoaded) {
            try {

                const config = await HttpService.getData<InterfaceConfiguration>(`${routeRoot}/interface`);
                cache.isCompanySearchEnabled = config.isCompanySearchEnabled;
                cache.isLoaded = true;
            } catch (err) {
                if ((err as any)?.message == "Network Error") {
                    return null;
                }
            }
        }
        return cache;
    },
};
