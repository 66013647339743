/**
 * Grid provider model.
 */
export class GridModel<T, TDataFilter extends DataFilter> {
  filter: PagedCollectionFilter & TDataFilter;
  collection: PagedCollection<T>;

  onTextSearch: (searchClause: string, prop: string) => void;
  onSearch: (searchClause?: any) => void;
  onFilter: () => void;
  clearSearch: (filter: PagedCollectionFilter) => void;
  sort: (params: ElSortParams) => void;
  goToPage: (page: number) => void;
  prev: () => void;
  next: () => void;
  first: () => void;
  last: () => void;

  isFirstPage: () => boolean;
  isLastPage: () => boolean;
}

/**
 * Element UI sort-change event params.
 */
export class ElSortParams {
  column: object;
  order: string;
  prop: string;
}

export class DataFilter {
  /**
   * String search
   */
  search: string;
}

/**
 * Paged collection fetch filter used to page/sort/search.
 */
export class PagedCollectionFilter {
  /**
   * Page to fetch.
   */
  page: number = 1;

  /**
   * Amount of items to fetch.
   */
  pageSize?: number;

  /**
   * Sort by KEY (entity field name).
   */
  sortBy?: string;

  /**
   * Sort direction (optional).
   * Allowed: "ascending" / "descending".
   */
  sortOperator?: string;

  /**
   * Include soft deleted entities?
   */
  includeDeleted?: boolean;

  /**
   * Collection owner user Id.
   */
  ownerId?: number;
}

/**
 * Paged collection model.
 */
export class PagedCollection<T> {
  pageCount: number;
  recordCount: number;
  items: T[];
}
