import { LatLng } from "leaflet";
import { Moment } from "moment";

export enum FreeTextSearchType {
  Any,
  All,
  Exact,
}
export class Filter {
  filterType: string;
  value: string;
  idValue?: any;
  from: string;
  to: string;
  noOfDays: number;
  operator: number;
  lat: number;
  lng: number;
  range: number;
  points?: number[][];
}
export class Field {
  fieldName: string;
  filters: Filter[];
}

export class SearchModel {

  name: string;
  id: number;
  fields: Field[];
  freeText: string;
  freeTextType: number | FreeTextSearchType;
  fieldsNotConverted?: string[];
  isAlertEnabled: boolean;
  searchType: number;
  isUpgradedSearch: boolean;

  constructor() {
    this.fields = [];
    this.freeText = "";
    this.freeTextType = FreeTextSearchType.Any;
    this.isAlertEnabled = true;
    this.isUpgradedSearch = false;
  }

}

export class CompanySearchResultModel {
  defaultRole: string;
  defaultRoleId: number;
  companyId: number;
  name: string;
  selected: boolean;
  isWatched: boolean;
  fullAddress: string;
  roles: RoleContactModel[];
  postcode: string;
  countyId: number;
  county: string;
  country: string;
  dateLastAction: Date | string | Moment;
  dateUpdated: Date | string | Moment;
  location: any;
}

export class RoleContactModel {
  roleId: number;
  role: string;
  email: string;
  name: string;
  contactId: number;
  surname: string;
  forename: string;
}

export class ProjectSearchResultModel {
  projectId: string;
  title: string;
  dateUpdated: Date;
  dateCreated: Date;
  stage: string;
  address: string;
  location: any;
  value: number;
  county: string;
  planningReference: string;
  companies: ProjectSearchResultCompanyModel[];
  description: string;
  selected: boolean;
  isWatched: boolean;
  tenderDeadline: Date;
}

export class ProjectSearchResultCompanyModel {
  roleName: string;
  roleID: number;
  companyID: number;
  name: string;
}

export class SearchResultContainer<T> {
  document: T;
  "@search.highlights": any;
}

export class SearchResults<T> {
  searchId: string;
  compiledSearch: string;
  facets: any;
  results: Array<SearchResultContainer<T>>;
  totalRecords: number;
  totalRecordsUnfiltered: number;
  isLimited: boolean;
}

export class DateRangeFilter {
  filterName: string;
  startDate: string;
  endDate: string;
  noOfdays?: number;
  value?: string;
}
