import moment from "moment";

export const cisDateFilter = (value: string) => {
  if (moment.isMoment(value)) {
    return value.format("DD/MM/YYYY");
  }
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.format("DD/MM/YYYY");
};

export const cisDateMonthFilter = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.format("MMM YYYY");
};

export const cisDateExtendedFilter = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.local().format("DD MMM YYYY");
};

export const cisDateTimeFilter = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.local().format("DD/MM/YYYY HH:mm:ss");
};

export const cisDeadlineDateTimeFilter = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.format("DD/MM/YYYY HH:mm");
};

export const cisTimeFilter = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.local().format("HH:mm");
};

export const recallDateFilter = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.format("DD/MM");
};

export const recallDateDayFilter = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.format("ddd");
};

export const cisDateTimeExportFilter = (value: string) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = moment.utc(value);
  return date.local().format("YYYYMMDDHHmmss");
};